const backgroundColors = {
  bgc1: "#93a7fe",
  bgc2: "#a9e874",
  bgc3: "#ff7a9a",
  bgc4: "#b378f7",
  bgc5: "#ff6674",
  bgc6: "#89e6e5",
  bgc7: "#ffcc64",
  bgc8: "#f8fbff",
};

const bodies = {
  body1: true,
  body2: true,
  body3: true,
  body4: true,
};

const bodyColors = {
  bc1: "#456dff",
  bc2: "#5a45ff",
  bc3: "#6dbb57",
  bc4: "#f55c80",
  bc5: "#7554ca",
  bc6: "#e24552",
  bc7: "#54d8c7",
  bc8: "#f3b63b",
};

const eyeses = {
  eyes1: true,
  eyes2: true,
  eyes3: true,
  eyes4: true,
  eyes5: true,
  eyes6: true,
};

const facialHairs = {
  facialHair1: true,
  facialHair2: true,
  facialHair3: true,
  facialHair4: true,
  facialHair5: true,
  facialHair6: true,
};

const facialHairColors = {
  fhc1: "#362d46",
  fhc2: "#665e97",
  fhc3: "#5ac4d4",
  fhc4: "#dee2f5",
  fhc5: "#6b4445",
  fhc6: "#f29c64",
  fhc7: "#e16381",
  fhc8: "#e15b65",
};

const hairs = {
  hair1: true,
  hair2: true,
  hair3: true,
  hair4: true,
  hair5: true,
  hair6: true,
  hair7: true,
  hair8: true,
  hair9: true,
  hair10: true,
  hair11: true,
  hair12: true,
  hair13: true,
  hair14: true,
  hair15: true,
  hair16: true,
  hair17: true,
  hair18: true,
  hair19: true,
  hair20: true,
};

const hairColors = {
  hc1: "#362d46",
  hc2: "#665e97",
  hc3: "#5ac4d4",
  hc4: "#dee2f5",
  hc5: "#6b4445",
  hc6: "#f29c64",
  hc7: "#e16381",
  hc8: "#e15b65",
};

const mouths = {
  mouth1: true,
  mouth2: true,
  mouth3: true,
  mouth4: true,
  mouth5: true,
  mouth6: true,
  mouth7: true,
};

const noses = {
  nose1: true,
  nose2: true,
  nose3: true,
};

const skinColors = {
  sc1: "#ffcc22",
  sc2: "#fbd2c7",
  sc3: "#f2ad9b",
  sc4: "#e58f7b",
  sc5: "#e4a06f",
  sc6: "#b1695a",
  sc7: "#92584b",
  sc8: "#613d36",
  sc9: "#c9e6dc",
};

export {
  backgroundColors,
  bodies,
  bodyColors,
  eyeses,
  facialHairs,
  facialHairColors,
  hairs,
  hairColors,
  mouths,
  noses,
  skinColors,
};
